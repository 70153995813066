import { CertikIcon, ArrowForwardIcon } from "../components/SharedComponents/Icons";
import { Fragment, useCallback } from "react";
import { Col, Row } from "antd";
import { COLORS, SERVICE_NAME } from "../const";
import SupportEmailLink from "../components/SupportEmailLink";
import { defaultStore, useStore } from "../hooks";
import { useRouter } from "next/router";
import { logout } from "../utils/frontend/fetchFromApi";
import { stdLogout } from "../utils/frontend/utils";
import { AppConfig } from "../const/appConfigs";

export default function Custom404() {
  const router = useRouter();
  const [store, setStore] = useStore();

  const onClickLogout = useCallback(async () => {
    await logout(store?.userInfo?.userId, store?.userInfo?.authData);
    {
      void router.push("/login");
      void stdLogout().then(() => {
        setStore(defaultStore);
      });
    }
  }, [router, setStore, store?.userInfo?.authData, store?.userInfo?.userId]);

  return (
    <Fragment>
      <Row gutter={40} style={{ display: "flex", justifyContent: "center", marginTop: 235 }}>
        <Col>
          <div style={{ paddingTop: 25 }}>
            <CertikIcon />
          </div>
        </Col>
        <Col style={{ width: 554 }}>
          <div
            style={{ fontSize: 64, fontWeight: 500, color: COLORS["gray-800"], paddingBottom: 16 }}
          >
            404
          </div>
          <div
            style={{ fontSize: 32, fontWeight: 400, color: COLORS["gray-800"], paddingBottom: 16 }}
          >
            Oops... The page is not available.
          </div>
          <div
            style={{ fontSize: 20, fontWeight: 500, color: COLORS["gray-700"], paddingBottom: 40 }}
          >
            It could be expired or a wrong URL. Please contact{" "}
            <span style={{ color: COLORS["red-200"] }}>
              <SupportEmailLink style={{ color: COLORS["red-200"] }}>
                CertiK {SERVICE_NAME} Support
              </SupportEmailLink>
            </span>{" "}
            for further assistance.
          </div>
          <div
            onClick={onClickLogout}
            style={{ fontSize: 20, fontWeight: 600, color: COLORS["gray-800"] }}
          >
            <div>
              <span style={{ cursor: "pointer" }}>
                Go to CertiK {AppConfig.serviceName} Login Page{" "}
              </span>
            </div>
            <div>
              <span style={{ cursor: "pointer", paddingLeft: 4 }}>
                <ArrowForwardIcon />
              </span>
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
}
