import { EMAIL_SUBJECT_TEMPLATE, ENCODED_EMAIL_BODY_TEMPLATE } from "../../const/certik";

/**
 * A stylable `mailto:` hyperlink for contacting CertiK support
 * @param {object} props
 * @param {boolean} [props.newTab] Opens in new tab
 * @param {ReactNode} [props.children] Link text
 * @param {CSSProperties} [props.style]
 */
export default function SupportEmailLink({ style, children, newTab = undefined, ...props }) {
  return (
    <a
      href={`mailto:${process.env.NEXT_PUBLIC_CLIENT_SUPPORT_EMAIL_ADDRESS}?subject=${EMAIL_SUBJECT_TEMPLATE}&body=${ENCODED_EMAIL_BODY_TEMPLATE}`}
      style={style}
      target={newTab && "_blank"}
      rel={newTab && "noreferrer"}
      {...props}
    >
      {children}
    </a>
  );
}
